import(/* webpackMode: "eager" */ "/vercel/path0/components/button/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/image/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/language-switcher/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/link/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/max-width/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/mobile-menu/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@storyblok/react/dist/bridge-loader.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@storyblok/react/dist/story.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/banner/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/contact-form/form.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/contact-form/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/cultural-site/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/cultural-site/slider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/expertise/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/heading/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero-image/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/header/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero-video/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/highlights/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/highlights/slider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/image-text/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/group/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/footer/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/links-list/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/partners/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/press/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/references/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/spectacle/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/teams/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/text/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/numbers/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/shows-list/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/text-and-cards/index.css")